/* Set global styles and the new font */
body, h1, p, a {
    font-family: 'Jost', sans-serif;
}

.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    text-align: center;
    padding: 0 20px;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    min-height: 100vh;  /* Change height to min-height */
    height: auto;  /* Allow it to grow based on content */
    overflow-y: auto; 
}

/* Mobile Responsiveness */


.images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
    flex-wrap: wrap;
}

.images-container img {
    flex: 1;
    height: auto;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    width: 100%; /* Full width by default, and then they will scale based on their parent container */
    max-width: 40vmin;
}

h1 {
    font-size: 2.5rem;
    margin: 20px 0;
    word-wrap: break-word;
}

p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    word-wrap: break-word;
    max-width: 700px;
    margin: 0 auto;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    h1 {
        font-size: 2rem; 
    }

    p {
        font-size: 1rem;
        margin-bottom: 20px; 
    }

    .landing-page {
        padding: 0 10px;
    }

    .images-container {
        flex-direction: column; /* images on top of each other for mobile */
        gap: 10px; /* reduce gap */
    }

    .images-container img {
        max-width: 90%;
        margin-bottom: 15px; /* added spacing between images when they are stacked */
    }

    .spotify-button {
        padding: 8px 15px; 
        font-size: 0.9rem; 
    }
}

.spotify-button {
    margin-top: 20px;
    background-color: #1DB954;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.spotify-button:hover {
    background-color: #1AA34A;
}

.spotify-button img {
    vertical-align: middle;
    margin-right: 10px;
}

.highlight {
    color: #1DB954;  /* Spotify green color */
    font-weight: bold;
}

.greeting, .instructions, .cta {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .landing-page {
        padding: 0 5%;
    }

    .images-container {
        flex-wrap: wrap; /* Allow images to wrap */
    }

    .images-container img {
        width: 100%;  /* Make the images fully responsive within their container */
        max-width: 100%;  /* No maximum width, allow it to fill the container */
        margin-bottom: 10px; /* Add a bottom margin for spacing when they wrap */
    }

    h1, p {
        padding: 0 5%;
    }

    .spotify-button {
        padding: 8px 15px;
        font-size: 0.9rem;
        margin-bottom: 15px;
    }
}