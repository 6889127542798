/* ArtistChecker.css */

.container {
    font-family: 'Jost', sans-serif; /* You can change this to the font you liked from earlier */
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem;
    background-color: #F5EACD;
    border-radius: 15px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.greeting {
    font-size: 1.5rem;
    color: #3E4152;
    margin-bottom: 1rem;
}

.message {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 1rem;
}

.artist-image {
    max-width: 60%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .greeting {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .message {
        font-size: 1rem;
    }

    .artist-image {
        max-width: 80%;
    }
}
